import React, { useEffect, useMemo, useState } from 'react'
import { useTranslations } from 'next-intl'
import Router from 'next/router'
import styled from 'styled-components'
import { Button, CircleSpinner, Stack, TypographyText } from '@nordic-web/ui-components'
import { getUserIdFromToken } from '@nordic-web/utils/authentication/token'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { BRAND, Brand, brandConfig } from '@/config/brand'
import { useMenuState } from '@/context/menu-state-context'
import { authenticationStore } from '@/features/auth/authentication-store'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthTracking } from '@/features/auth/tracking-events'
import type { TokenPair } from '@/features/auth/types'
import { nextConfig } from '@/helpers/env'
import { getPageName } from '@/helpers/get-page-name'

const AUTH_APP_HOST = nextConfig.string('AUTH_APP_HOST')

export const PATH_LOGIN = 'login'
export const PATH_FORGOT_PASSWORD = 'forgot-password'
export const PATH_CHANGE_PASSWORD = 'change-password'
export const PATH_SIGNUP = 'signup'
export const PATH_ACTIVATE_OPERATOR = 'activate-operator'

const Root = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})

type AuthIframeProps = {
  path:
    | typeof PATH_LOGIN
    | typeof PATH_FORGOT_PASSWORD
    | typeof PATH_CHANGE_PASSWORD
    | typeof PATH_SIGNUP
    | typeof PATH_ACTIVATE_OPERATOR
  queryParams?: Record<string, string>
  onBack?: () => void
  showBack?: boolean
  redirecUrlOnSuccess?: string | null
}

type AuthPayload = TokenPair & { isSignup: boolean }

export const AuthIframe = ({
  path = PATH_LOGIN,
  queryParams = {},
  onBack,
  showBack = false,
  redirecUrlOnSuccess,
}: AuthIframeProps) => {
  const { setIsMobileMenuOpen } = useMenuState()
  const { dispatch: authModalDispatch } = useAuthModal()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [iframeKey, setIframeKey] = useState(0)

  const t = useTranslations()

  const iframeSrc = useMemo(() => {
    const query: Record<string, string> = {
      client: brandConfig.clientName,
    }

    if (showBack) {
      query.back = 'true'
    }

    const brandPath = BRAND === Brand.TV4 ? 'tv4' : 'mtv'
    const qString = new URLSearchParams({ ...query, ...queryParams }).toString()
    return `${AUTH_APP_HOST}/${brandPath}/${path}?${qString}`
  }, [path, queryParams, showBack])

  useEffect(() => {
    if (path === PATH_LOGIN || path === PATH_SIGNUP) {
      AuthTracking.overlayDisplay(getPageName() ?? '')
    }
  }, [path])

  useEffect(() => {
    const handle = async (message: MessageEvent) => {
      const { source, origin, data } = message
      if (!source || origin !== AUTH_APP_HOST) return

      switch (data.type) {
        case 'loadSuccess':
          return setIsLoading(false)

        case 'loginSuccess':
          {
            const payload = data.payload as AuthPayload
            authenticationStore.login(payload.refreshToken, payload.accessToken)

            const userId = getUserIdFromToken(payload?.accessToken)
            const page = getPageName() ?? ''
            if (payload.isSignup) {
              AuthTracking.onSignupSuccess(userId, page)
            } else {
              AuthTracking.onLoginSuccess(userId, page)
            }

            if (redirecUrlOnSuccess) {
              Router.push(redirecUrlOnSuccess)
            }

            authModalDispatch({ type: 'close-auth-modal' })
            setIsMobileMenuOpen(false)
          }
          break

        case 'back':
          return onBack?.()

        case 'getAuthToken': {
          const token = await authenticationStore.getValidAccessToken()
          return source.postMessage(
            {
              type: 'authToken',
              token,
            },
            {
              targetOrigin: AUTH_APP_HOST,
            }
          )
        }

        default:
          console.log('Unhandled message', message)
      }
    }

    window.addEventListener('message', handle)

    return () => {
      window.removeEventListener('message', handle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirecUrlOnSuccess])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading((loading) => {
        if (loading) {
          setHasError(true)
          return false
        }
        return loading
      })
    }, 10000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [iframeKey])

  // The auth app sends a "loadSuccess" message when loaded to better handle timeout and error cases. However, if we SSR
  // the auth iframe the event will trigger before we have setup the listening. So we make sure that the auth iframe is loaded
  // after the listening has started. In all places except for /glomt-losenord the auth iframe is triggered by client-side action
  // either way, so it is not important to SSR.
  const isClientSide = useIsClientSide()
  if (!isClientSide) {
    return null
  }

  return (
    <Root>
      {isLoading && <CircleSpinner nwPaddingTop />}
      {hasError && (
        <Stack nwGap={4}>
          <TypographyText nwVariant="body1" nwColor="secondary">
            {t('error_message__try_again')}
          </TypographyText>
          <Button
            onClick={() => {
              setIsLoading(true)
              setHasError(false)
              setIframeKey((prevKey) => prevKey + 1)
            }}
          >
            {t('error_message__button')}
          </Button>
        </Stack>
      )}
      <iframe
        key={iframeKey}
        data-testid={'auth-iframe'}
        height={isLoading || hasError ? 0 : '100%'}
        src={iframeSrc}
        title={'Login'}
        width={'100%'}
      />
    </Root>
  )
}
